// Initialize Firebase Storage
export  const firebaseConfig = {

    apiKey: "AIzaSyCmaHA3w-t4Z-Dek_j8xVyRDVdPvbxRLic",
  
    authDomain: "iimcip-contest-portal.firebaseapp.com",
  
    projectId: "iimcip-contest-portal",
  
    storageBucket: "iimcip-contest-portal.appspot.com",
  
    messagingSenderId: "373688280942",
  
    appId: "1:373688280942:web:77aafd9bbce9d0ccefc37f",
  
    measurementId: "G-NZ571S9QBX"
  
  };
  
  
export const languageType = [
  {
    value:'hindi',
    label:'Hindi'
  },
  {
    value:'english',
    label:'English'
  },
  {
    value:'bengali',
    label:'Bengali'
  },
  {
    value:'tamil',
    label:'Tamil'
  },
  {
    value:'marathi',
    label:'Marathi'
  }
]
// export const languageType = [
//   {
//     value:'hindi',
//     label:'Hindi'
//   },
//   {
//     value:'english',
//     label:'English'
//   },
//   {
//     value:'garo',
//     label:'Garo'
//   },
//   {
//     value:'khasi',
//     label:'Khasi'
//   }
// ]


export const checkDateIsAfterCurrentDate = (date)=>{
  
  const givenDate = new Date(date.replace("T00:00:00.000Z",'T23:59:59'));
  const currentDate = new Date();

  // console.log(givenDate > currentDate,"givenDate > currentDate",currentDate,"---",givenDate,"date",date)

  if (givenDate > currentDate) {
    return false;
  } 
  return true
}