// BRFORE EMAIL VALIDATION IN ELEMENT TYPE EMAIL
import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Paper,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputLabel,
  Input,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import FormPreview from "./Components/Pages/FormPreview";
import CustomizedProgressBars from "./Components/Pages/CustomizedProgressBars";
import moment from "moment";
import { checkDateIsAfterCurrentDate } from "./Utility/Helper";

const VerticalLinearStepper = ({ id, contestData }) => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null); // Track the file to delete
  const [loading, setLoading] = useState(false); // State to track loading status
  const getUrl = useParams(); // State to track preview status
  const elementRef = useRef([]); // Create Dynamic ref
  const matches = useMediaQuery("(min-width:600px)");

  const toggleLoading = (status) => {
    setLoading(status);
  };
  const totalSteps = data.filter((step) => step.type === "section").length;
  const item = localStorage.getItem("user");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isDateValid = (dateString) => {
    const selectedDate = new Date(dateString);
    const cutoffDate = new Date();
    cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);
    return selectedDate <= cutoffDate;
  };
  const handleNext = async () => {
    const currentSection = data[activeStep];
    const emailElement = currentSection.elements.find(
      (element) => element.type === "email"
    );
    // Bdate validation
    // const bdateElement = currentSection.elements.find(
    //   (element) => element.type === "bdate"
    // );

    // if (bdateElement) {
    //   const bdateValue = formData[bdateElement.id];

    //   if (bdateElement.required && !bdateValue) {
    //     toast.error("Birthdate is required", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       theme: "colored",
    //       hideProgressBar: true,
    //     });
    //     return;
    //   }

    //   if (bdateValue) {
    //     const selectedDate = new Date(bdateValue);
    //     const cutoffDate = new Date();
    //     cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);

    //     if (selectedDate > cutoffDate) {
    //       toast.error("You must be at least 18 years old", {
    //         position: "top-right",
    //         autoClose: 3000,
    //         theme: "colored",
    //         hideProgressBar: true,
    //       });
    //       return;
    //     }
    //   }
    // }

    const bdateElements = currentSection.elements.filter(
      (element) => element.type === "bdate"
    );

    for (const bdateElement of bdateElements) {
      const bdateValue = formData[bdateElement.id];

      if (bdateElement.required && !bdateValue) {
        toast.error(`${bdateElement.value} is required`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        console.log(`Validation Failed: ${bdateElement.value} is missing`);
        return;
      }

      if (bdateValue) {
        const isValid = isDateValid(bdateValue);
        console.log(`Birthdate Validation for ${bdateElement.value}:`, isValid);

        if (!isValid) {
          toast.error(
            `You must be at least 18 years old for ${bdateElement.value}`,
            {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            }
          );
          return;
        }
      }
    }

    // If the current section has an email element, validate its value
    if (emailElement) {
      const emailValue = formData[emailElement.id];
      if (emailValue && !validateEmail(emailValue)) {
        // If the email is not valid, display an error message and return
        toast.error("Please enter a valid email address", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        return;
      }
    }
    // Number lement check
    // const numberElement = currentSection.elements.find(
    //   (element) => element.type === "number"
    // );

    // // If the current section has a number element, validate its value
    // if (numberElement) {
    //   const numberValue = formData[numberElement.id];
    //   if (numberValue && isNaN(numberValue)) {
    //     // If the value is not a valid number, display an error message and return
    //     toast.error("Please enter a valid number", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       theme: "colored",
    //       hideProgressBar: true,
    //     });
    //     return;
    //   }
    // }

    // Phone element check
    const phoneElement = currentSection.elements.find(
      (element) => element.type === "phone"
    );
    if (phoneElement && phoneElement.required) {
      const phoneValue = formData[phoneElement.id];
      const phoneRegex = /^\+\d{1,3}\d{10}$/; // e.g., +911234567890
      if (!phoneValue || !phoneRegex.test(phoneValue)) {
        toast.error(
          "Please enter a valid phone number. It should have a 1-3 digit country code followed by a 10-digit number.",
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );
        return;
      }
    }
    const multiSelectElement = currentSection.elements.find(
      (element) => element.type === "multi-select" && element.required
    );
    if (multiSelectElement) {
      const multiSelectValue = formData[multiSelectElement.id];
      if (
        !multiSelectValue ||
        !Array.isArray(multiSelectValue) ||
        multiSelectValue.length === 0
      ) {
        toast.error(
          `Please select at least one option for ${multiSelectElement.value}`,
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );
        return;
      }
    }

    // Validate required rich text element, if present
    const richElement = currentSection.elements.find(
      (element) => element.type === "rich" && element.required
    );

    if (richElement) {
      const richValue = formData[richElement.id];

      // Remove all HTML tags and decode HTML entities
      const strippedValue = richValue
        .replace(/<[^>]+>/g, "") // Remove HTML tags
        .replace(/&nbsp;/g, "") // Remove non-breaking spaces
        .trim(); // Trim whitespace

      // Check if the stripped value is empty
      if (!strippedValue) {
        toast.error("Please fill the Text-Editor as its a required field", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        return;
      }
    }
    // Proceed to the next step if the email is valid
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const formattedData = JSON.parse(JSON.stringify(data)); // Deep copy of data

    formattedData.forEach((section) => {
      section.elements.forEach((element) => {
        element.answer = formData[element.id] || ""; // Assign the answer field
      });
    });
    toggleLoading(true); // Set loading to true when fetching data
    // For Patching user data to the database every time use clicks next button
    try {
      // Log the base URL

      if (userData) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/application/${userData.userId}`,
          {
            status: "draft",
            answerFields: {
              userAnswer: formattedData,
              title: title,
              description: description,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
              "Cache-Control": "no-cache",
              "Cache-Control": "no-cache",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          toggleLoading(false); // Set loading to false when fetching data is completed successfully
        }
      } else {
        navigate(`/login/${getUrl.contestId}`);
        localStorage.removeItem("user");
      }
    } catch (error) {
      console.error("Error While subhmit", error?.response?.data?.message);
      toggleLoading(false); // Set loading to false when fetching data is completed successfully

      toast.error(error?.response?.data?.message[0], {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }

    // For application status to turn in-Progress after clicking first time next button
  };
  const handleDraft = async () => {
    const currentSection = data[activeStep];
    const emailElement = currentSection.elements.find(
      (element) => element.type === "email"
    );
    if (emailElement && emailElement.required) {
      const emailValue = formData[emailElement.id];
      if (!emailValue || !validateEmail(emailValue)) {
        toast.error("Please enter a valid email address", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        return;
      }
    }

    const phoneElement = currentSection.elements.find(
      (element) => element.type === "phone"
    );
    if (phoneElement && phoneElement.required) {
      const phoneValue = formData[phoneElement.id];
      const phoneRegex = /^\+\d{1,3}\d{10}$/; // e.g., +911234567890
      if (!phoneValue || !phoneRegex.test(phoneValue)) {
        toast.error(
          "Please enter a valid phone number. It should have a 1-3 digit country code followed by a 10-digit number.",
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );
        return;
      }
    }

    const formattedData = JSON.parse(JSON.stringify(data)); // Deep copy of data

    formattedData.forEach((section) => {
      section.elements.forEach((element) => {
        element.answer = formData[element.id] || ""; // Assign the answer field
      });
    });
    toggleLoading(true); // Set loading to true when fetching data
    // For Patching user data to the database every time use clicks next button
    try {
      // Log the base URL

      if (userData) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/application/${userData.userId}`,
          {
            status: "draft",
            answerFields: {
              userAnswer: formattedData,
              title: title,
              description: description,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
              "Cache-Control": "no-cache",
              "Cache-Control": "no-cache",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          toggleLoading(false); // Set loading to false when fetching data is completed successfully
          toast.success(
            "You have saved the fields of this form as drafts successfully.",
            {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            }
          );
        }
      } else {
        navigate(`/login/${getUrl.contestId}`);
        localStorage.removeItem("user");
      }
    } catch (error) {
      console.error("Error While subhmit", error?.response?.data?.message);
      toggleLoading(false); // Set loading to false when fetching data is completed successfully

      toast.error(error?.response?.data?.message[0], {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }

    // For application status to turn in-Progress after clicking first time next button
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({});
  };
  const isFormValid = () => {
    const currentSection = data[activeStep];
    const fields = currentSection.elements;
    return fields.every((field) => {
      if (field.required) {
        if (field.type === "check") {
          // For checkboxes, at least one option must be selected
          return (
            formData[field.id]?.length > 0 // Check if any options are selected
          );
        } else {
          return formData[field.id] !== undefined && formData[field.id] !== "";
        }
      } else {
        return true; // Non-required fields can be blank
      }
    });
  };
  const fetchQuestions = async () => {
    try {
      toggleLoading(true); // Set loading to false when fetching data is completed successfully

      const accessToken = userData.accessToken;
      const questionsResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-contest-data`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Cache-Control": "no-cache",
          },
        }
      );
      if (!questionsResponse.ok) {
        throw new Error("Failed to fetch questions data");
      } else {
        toggleLoading(false); // Set loading to false when fetching data is completed successfully

        const questionsData = await questionsResponse.json();
        // Handle the questions data as needed
        setData(questionsData.formFields.data);
        console.log("Questions Data---", questionsData.formFields.data);
        setTitle(questionsData?.formFields?.title || "");
        setDescription(questionsData?.formFields?.description || "");
      }
    } catch (error) {
      toggleLoading(false); // Set loading to false when fetching data is completed successfully
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
      console.error("Error fetching questions:", error);
    }
  };
  const userData = JSON.parse(item);

  const onSubmit = async () => {
    // cross Check in last step if there any email field then its need to be cheched if valid email then submit api else throw error message
    const currentSection = data[activeStep];
    const emailElement = currentSection.elements.find(
      (element) => element.type === "email"
    );

    // If the current section has an email element, validate its value
    if (emailElement) {
      const emailValue = formData[emailElement.id];
      if (emailValue && !validateEmail(emailValue)) {
        // If the email is not valid, display an error message and return
        toast.error("Please enter a valid email address", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        return;
      }
    }
    const phoneElement = currentSection.elements.find(
      (element) => element.type === "phone"
    );
    if (phoneElement && phoneElement.required) {
      const phoneValue = formData[phoneElement.id];
      const phoneRegex = /^\+\d{1,3}\d{10}$/; // e.g., +911234567890
      if (!phoneValue || !phoneRegex.test(phoneValue)) {
        toast.error(
          "Please enter a valid phone number. It should have a 1-3 digit country code followed by a 10-digit number.",
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );
        return;
      }
    }
    // Bdate validation
    // const bdateElement = currentSection.elements.find(
    //   (element) => element.type === "bdate"
    // );

    // if (bdateElement) {
    //   const bdateValue = formData[bdateElement.id];

    //   if (bdateElement.required && !bdateValue) {
    //     toast.error("Birthdate is required", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       theme: "colored",
    //       hideProgressBar: true,
    //     });
    //     return;
    //   }

    //   if (bdateValue) {
    //     const selectedDate = new Date(bdateValue);
    //     const cutoffDate = new Date();
    //     cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);

    //     if (selectedDate > cutoffDate) {
    //       toast.error("You must be at least 18 years old", {
    //         position: "top-right",
    //         autoClose: 3000,
    //         theme: "colored",
    //         hideProgressBar: true,
    //       });
    //       return;
    //     }
    //   }
    // }

    const bdateElements = currentSection.elements.filter(
      (element) => element.type === "bdate"
    );

    for (const bdateElement of bdateElements) {
      const bdateValue = formData[bdateElement.id];

      if (bdateElement.required && !bdateValue) {
        toast.error(`${bdateElement.value} is required`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        console.log(`Validation Failed: ${bdateElement.value} is missing`);
        return;
      }

      if (bdateValue) {
        const isValid = isDateValid(bdateValue);
        console.log(`Birthdate Validation for ${bdateElement.value}:`, isValid);

        if (!isValid) {
          toast.error(
            `You must be at least 18 years old for ${bdateElement.value}`,
            {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            }
          );
          return;
        }
      }
    }
    const multiSelectElement = currentSection.elements.find(
      (element) => element.type === "multi-select" && element.required
    );
    if (multiSelectElement) {
      const multiSelectValue = formData[multiSelectElement.id];
      if (
        !multiSelectValue ||
        !Array.isArray(multiSelectValue) ||
        multiSelectValue.length === 0
      ) {
        toast.error(
          `Please select at least one option for ${multiSelectElement.value}`,
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );
        return;
      }
    }

    const richElement = currentSection.elements.find(
      (element) => element.type === "rich" && element.required
    );

    if (richElement) {
      const richValue = formData[richElement.id];

      // Remove all HTML tags and decode HTML entities
      const strippedValue = richValue
        .replace(/<[^>]+>/g, "") // Remove HTML tags
        .replace(/&nbsp;/g, "") // Remove non-breaking spaces
        .trim(); // Trim whitespace

      // Check if the stripped value is empty
      if (!strippedValue) {
        toast.error("Please fill the Text-Editor as its a required field", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        return;
      }
    }

    // Continue with form submission
    const formattedData = JSON.parse(JSON.stringify(data)); // Deep copy of data
    formattedData.forEach((section) => {
      section.elements.forEach((element) => {
        element.answer = formData[element.id] || ""; // Assign the answer field
      });
    });
    try {
      toggleLoading(true);

      if (userData) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/application/${userData.userId}`,
          {
            status: "draft",
            answerFields: {
              userAnswer: formattedData,
              title: title,
              description: description,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );
        if (response.status >= 200 && response.status < 210) {
          // toast.success("Data Submitted successfully", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   theme: "colored",
          //   hideProgressBar: true,
          // });
          navigate(`/dashboard/FormPreview/${userData.contestId}`);
          toggleLoading(false); // Set loading to false when fetching data is completed successfully
        }
      } else {
        navigate(`/login/${getUrl.contestId}`);
        localStorage.removeItem("user");
      }
    } catch (error) {
      toggleLoading(false); // Set loading to false when fetching data is completed successfully

      toast.error(error?.response?.data?.message[0], {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (!userData) {
      navigate(`/login/${getUrl.contestId}`);
      return;
    }

    const fetchData = async () => {
      try {
        toggleLoading(true);
        const accessToken = userData.accessToken;
        const profileResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/application/profile-info`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );
        if (!profileResponse.ok) {
          throw new Error("Failed to fetch profile data");
        } else {
          const profileData = await profileResponse.json();

          if (
            profileData?.answerFields === null ||
            profileData?.answerFields === undefined ||
            Object.keys(profileData?.answerFields).length === 0
          ) {
            fetchQuestions();
          } else {
            if (profileData?.status === "submit") {
              navigate(`/dashboard/FormPreview/${userData.contestId}`);
            } else if (profileData?.status === "junk") {
              navigate("/junk");
            } else {
              toggleLoading(false);
              setTitle(profileData?.answerFields?.title || "");
              setDescription(profileData?.answerFields?.description || "");
              setData(profileData?.answerFields?.userAnswer || []);

              const initialFormData = {};
              profileData?.answerFields?.userAnswer.forEach((section) => {
                section.elements.forEach((element) => {
                  initialFormData[element.id] = element.answer || "";
                });
              });
              setFormData(initialFormData);
            }
          }
        }
      } catch (error) {
        toggleLoading(false);
        toast.error(error.message || "Error fetching data", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [navigate]); // Only depend on userData.accessToken
  // Include any dependencies here
  // const handleFileUpload = async (file, element) => {
  //   try {
  //     if (!file) {
  //       // If no file is selected and the field is required, show an error message
  //       if (element.required) {
  //         toast.error("Please select a file as it's a required field", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           theme: "colored",
  //           hideProgressBar: true,
  //         });
  //       }
  //       return; // Return early if no file is selected
  //     }

  //     const formData = new FormData();
  //     formData.append("file", file);

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/application/contest-file`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${userData.accessToken}`,
  //         },
  //       }
  //     );

  //     if (response.status >= 200 && response.status < 300) {
  //       const { originalFileName, uploadedFileName } = response.data;
  //       const updatedAnswer = { originalFileName, uploadedFileName };

  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         [element.id]: updatedAnswer,
  //       }));
  //       // Handle response as needed
  //       toast.success("File uploaded successfully", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         theme: "colored",
  //         hideProgressBar: true,
  //       });
  //     } else {
  //       // Display error message if response status is not successful
  //       throw new Error("Failed to upload file. Please try again later.");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     // Handle error
  //     toast.error("Error uploading file. Please try again later", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       theme: "colored",
  //       hideProgressBar: true,
  //     });
  //   }
  // };

  const handleFileUpload = async (file, element) => {
    try {
      if (!file) {
        // If no file is selected and the field is required, show an error message
        if (element.required) {
          toast.error("Please select a file as it's a required field", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          });
        }
        element.value = "";

        return; // Return early if no file is selected
      }

      // Check file extension
      const allowedExtensions = [
        ".pdf",
        ".jpg",
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
        ".jpeg",
        ".xls",
        ".xlsx",
      ];
      const fileExtension = file.name.split(".").pop().toLowerCase(); // Get the extension after the last dot

      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        toast.error(
          "Please upload a .pdf / .jpg / .jpeg / .doc /.docx / .ppt / .pptx / .xls / .xlsx file only",
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          }
        );

        return; // Return early if file extension is not allowed
      }

      if (file.size > 5 * 1024 * 1024) {
        elementRef.current[element.id].value = null;
        toast.error("The size of your file is more than 5MB.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });

        return; // Return early if file extension is not allowed
      }

      setLoading(true); // Set loading state to true

      const formData = new FormData();
      formData.append("file", file);
      //For PHP IMPORT
      const { contestId } = getUrl;
      formData.append("contestId", contestId);

      const response = await axios.post(
        // `${process.env.REACT_APP_BASE_URL}/application/contest-file`,
        // For PHP IMPORT
        `${
          process.env.REACT_APP_MODE === "production"
            ? process.env.REACT_APP_IMPORT_FILE_BASE_URL_PHP
            : process.env.REACT_APP_BASE_URL
        }/application/contest-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      );

      console.log(response, "response");

      if (response.status >= 200 && response.status < 300) {
        const { originalFileName, uploadedFileName } = response.data;
        const updatedAnswer = { originalFileName, uploadedFileName };

        setFormData((prevFormData) => ({
          ...prevFormData,
          [element.id]: updatedAnswer,
        }));
        // Handle response as needed
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        // Display error message if response status is not successful
        throw new Error("Failed to upload file. Please try again later.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error
      toast.error("Error uploading file. Please try again later", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false); // Set loading state back to false after upload completion or error
    }
  };

  const handleAudioUpload = async (file, element) => {
    try {
      if (!file) {
        // If no file is selected and the field is required, show an error message
        if (element.required) {
          toast.error("Please select a file as it's a required field", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          });
        }
        return; // Return early if no file is selected
      }

      // Check file extension
      const allowedExtensions = [".mp3", ".mp4"];
      const fileExtension = file.name.slice(-4).toLowerCase(); // Get the last 4 characters of the file name (extension)

      if (!allowedExtensions.includes(fileExtension)) {
        elementRef.current[element.id].value = null;
        toast.error("Please upload an .mp3 or .mp4 file only", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });

        return; // Return early if file extension is not allowed
      }

      if (file.size > 5 * 1024 * 1024) {
        elementRef.current[element.id].value = null;
        toast.error("The size of your file is more than 5MB.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });

        return; // Return early if file extension is not allowed
      }
      setLoading(true); // Set loading state to true

      const formData = new FormData();
      formData.append("file", file);

      //For PHP IMPORT
      const { contestId } = getUrl;
      formData.append("contestId", contestId);

      const response = await axios.post(
        // `${process.env.REACT_APP_BASE_URL}/application/contest-file`,
        // For PHP IMPORT
        `${
          process.env.REACT_APP_MODE === "production"
            ? process.env.REACT_APP_IMPORT_FILE_BASE_URL_PHP
            : process.env.REACT_APP_BASE_URL
        }/application/contest-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const { originalFileName, uploadedFileName } = response.data;
        const updatedAnswer = { originalFileName, uploadedFileName };

        setFormData((prevFormData) => ({
          ...prevFormData,
          [element.id]: updatedAnswer,
        }));
        // Handle response as needed
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        // Display error message if response status is not successful
        throw new Error("Failed to upload file. Please try again later.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error
      toast.error("Error uploading file. Please try again later", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false); // Set loading state back to false after upload completion or error
    }
  };

  if (loading) {
    return (
      <>
        <CustomizedProgressBars />
      </>
    );
  }
  const handleDeleteFile = async (elementId) => {
    try {
      const { uploadedFileName } = formData[elementId];

      //For PHP IMPORT

      const { contestId } = getUrl;

      const payload = {
        contestId,
        uploadedFileName,
      };

      await axios
        .delete(
          // `${process.env.REACT_APP_BASE_URL}/application/contest-file`,
          // For PHP IMPORT
          `${
            process.env.REACT_APP_MODE === "production"
              ? process.env.REACT_APP_IMPORT_FILE_BASE_URL_PHP
              : process.env.REACT_APP_BASE_URL
          }/application/contest-file`,
          {
            data: payload,
          },

          {
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${userData.accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response, "response");
          if (response.status === 200 || response.status === 201) {
            toast.success("File deleted successfully", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            });
            setFormData((prevFormData) => ({
              ...prevFormData,
              [elementId]: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } catch (error) {}

    // Remove the file from formData
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [elementId]: "",
    // }));
  };

  console.log("Data ---", data);
  return (
    <>
      {/* Home page content */}
      {/* <Grid container spacing={2} justifyContent="center">
        <Grid item xs={8} textAlign="center">
          {" "}
          <Typography variant="h4">
            <span
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontFamily: "Arial, sans-serif",
                color: "navy",
              }}
            >
              {title ? title : ""}
            </span>
          </Typography>
      
        </Grid>
      </Grid> */}

      <Grid
        container
        spacing={2}
        justifyContent="center"
        xs={10}
        sx={{
          // border: "1px solid #ccc",
          margin: "auto",
          mt: 5,
          // maxWidth: 10,
          borderRadius: 8,
          marginBottom: 5,
        }}
      >
        <Grid item xs={2.5} style={{ marginRight: "20px" }} mt={1}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {data.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.value}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={8.5}>
          <Box sx={{ p: 2, minHeight: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {data.map((step, index) => (
                <Step key={index}>
                  <StepContent>
                    {step.elements.map((element, i) => (
                      <Grid item xs={12} key={i} sx={{ mb: 2 }}>
                        {element.type === "text" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              value={formData[element.id] || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [element.id]: e.target.value,
                                })
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            />
                            {/* {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}
                        {element.type === "rich" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block",
                                marginBottom: "8px",
                                whiteSpace: "pre-line",
                                fontWeight: "bold",
                              }}
                            >
                              {element.value}-{" "}
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>

                            <ReactQuill
                              theme="snow"
                              value={formData[element.id] || ""}
                              onChange={(content) => {
                                setFormData({
                                  ...formData,
                                  [element.id]: content, // Store the rich text HTML content
                                });
                              }}
                              style={{ minHeight: "150px" }}
                            />

                            {/* {element.required &&
                              !formData[element.id]?.trim() && (
                                <FormHelperText style={{ color: "red" }}>
                                  This field is required
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}
                        {element.type === "email" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block",
                                marginBottom: "8px",
                                whiteSpace: "pre-line",
                                fontWeight: "bold",
                              }}
                            >
                              {element.value}
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              value={formData[element.id] || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [element.id]: e.target.value,
                                })
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            />
                            {/* {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}

                        {element.type === "file" && (
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "1rem" }}
                            component="fieldset"
                          >
                            {formData[element.id]?.originalFileName && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    // color: 'rgba(0, 0, 0, 0.6)',
                                    // fontWeight: '400',
                                    fontSize: "1rem",
                                    lineHeight: "1.4375em",
                                    letterSpacing: "0.00938em",
                                    padding: "0",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      // color: "rgba(0, 0, 0, 0.6)",

                                      fontSize: "1rem",
                                      lineHeight: "1.4375em",
                                      letterSpacing: "0.00938em",
                                      padding: "0",
                                      whiteSpace: "pre-line", // Preserves multiline data
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {element.value}
                                    {element.required && (
                                      <>
                                        <span
                                          style={{
                                            color: "red",
                                            marginLeft: "0.2rem",
                                          }}
                                        >
                                          *
                                        </span>
                                        <span style={{ marginRight: "0.5rem" }}>
                                          :
                                        </span>
                                      </>
                                    )}
                                  </Typography>
                                  <Typography variant="body1">
                                    : {formData[element.id].originalFileName}
                                  </Typography>
                                  <IconButton
                                    onClick={() => handleDeleteFile(element.id)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Typography>
                              </div>
                            )}
                            {formData[element.id]?.originalFileName ? null : (
                              <>
                                {/* <InputLabel shrink>
                                  {element.value}
                                  {element.required && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </InputLabel> */}
                                <label
                                  style={{
                                    display: "block", // Ensures the label is on its own line
                                    marginBottom: "8px", // Adds space between label and input
                                    whiteSpace: "pre-line", // Preserves multiline data
                                    fontWeight: "bold", // Optional: Makes the label bold for clarity
                                  }}
                                >
                                  {element.value}
                                  {element.required && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </label>
                                <Input
                                  type="file"
                                  onChange={(e) =>
                                    handleFileUpload(e.target.files[0], element)
                                  }
                                  inputRef={(ref) => {
                                    elementRef.current[element.id] = ref;
                                  }}
                                  style={{ marginTop: "3px" }}
                                  error={
                                    formData[element.id] === "" &&
                                    element.required
                                  }
                                />
                                <FormHelperText>
                                  Limitation of the file size is 5MB.
                                </FormHelperText>
                              </>
                            )}
                            {/* <Input
                              type="file"
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0], element)
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            /> */}

                            {/* {formData[element.id] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}
                        {element.type === "audio" && (
                          <FormControl
                            fullWidth
                            style={{ marginBottom: "1rem" }}
                            component="fieldset"
                          >
                            {formData[element.id]?.originalFileName && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    // color: 'rgba(0, 0, 0, 0.6)',
                                    // fontWeight: '400',
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    lineHeight: "1.4375em",
                                    letterSpacing: "0.00938em",
                                    padding: "0",
                                  }}
                                >
                                  {element.value}
                                  {element.required && (
                                    <>
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: "0.2rem",
                                        }}
                                      >
                                        *
                                      </span>
                                    </>
                                  )}
                                  <span style={{ marginRight: "0.5rem" }}>
                                    :
                                  </span>
                                </Typography>
                                <Typography variant="body1">
                                  : {formData[element.id].originalFileName}
                                </Typography>
                                <IconButton
                                  onClick={() => handleDeleteFile(element.id)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            )}
                            {formData[element.id]?.originalFileName ? null : (
                              <>
                                {/* <InputLabel shrink>
                                  {element.value}
                                  {element.required && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </InputLabel> */}
                                {/* <FormLabel component="legend" > */}

                                <label
                                  style={{
                                    display: "block", // Ensures the label is on its own line
                                    marginBottom: "8px", // Adds space between label and input
                                    whiteSpace: "pre-line", // Preserves multiline data
                                    fontWeight: "bold", // Optional: Makes the label bold for clarity
                                  }}
                                >
                                  {element.value}
                                  {element.required && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}
                                </label>
                                {/* </FormLabel> */}
                                <Input
                                  type="file"
                                  onChange={(e) =>
                                    handleAudioUpload(
                                      e.target.files[0],
                                      element
                                    )
                                  }
                                  inputRef={(ref) => {
                                    elementRef.current[element.id] = ref;
                                  }}
                                  style={{ marginTop: "3px" }}
                                  error={
                                    formData[element.id] === "" &&
                                    element.required
                                  }
                                />
                                <FormHelperText>
                                  Limitation of the file size is 5MB.
                                </FormHelperText>
                              </>
                            )}
                            {/* <Input
                              type="file"
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0], element)
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            /> */}

                            {formData[element.id] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                        {element.type === "number" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              type="text" // Set the input type to "text"
                              value={formData[element.id] || ""}
                              onChange={(e) => {
                                const userInput = e.target.value;
                                // Allow only numbers by replacing any non-numeric characters
                                const sanitizedInput = userInput.replace(
                                  /\D/g,
                                  ""
                                );
                                setFormData({
                                  ...formData,
                                  [element.id]: sanitizedInput,
                                });
                              }}
                              error={
                                formData[element.id] === "" && element.required
                              }
                            />
                            {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}

                        {element.type === "phone" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block",
                                marginBottom: "8px",
                                whiteSpace: "pre-line",
                                fontWeight: "bold",
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              type="text"
                              value={formData[element.id] ?? "+91"} // Default to +91
                              onChange={(e) => {
                                let userInput = e.target.value.trim();

                                // Ensure "+" is always at the beginning
                                if (!userInput.startsWith("+")) {
                                  userInput =
                                    "+" + userInput.replace(/\D/g, ""); // Remove non-numeric chars
                                } else {
                                  userInput =
                                    "+" +
                                    userInput.substring(1).replace(/\D/g, ""); // Only numbers after "+"
                                }

                                // Extract country code (1-3 digits) and phone number (up to 10 digits)
                                const match = userInput.match(
                                  /^\+(\d{1,3})(\d{0,10})$/
                                );
                                if (match) {
                                  const countryCode = match[1];
                                  const phoneNumber = match[2];
                                  // Ensure user can edit freely without resetting to +91 if country code is longer than 3 digits
                                  if (countryCode.length > 3) {
                                    userInput = `+${countryCode.slice(
                                      0,
                                      3
                                    )}${phoneNumber}`;
                                  }
                                }

                                setFormData({
                                  ...formData,
                                  [element.id]: userInput,
                                });
                              }}
                              error={
                                formData[element.id] &&
                                formData[element.id].length < 12 && // Require at least 13 characters: e.g., +91 followed by 10 digits
                                element.required
                              }
                            />

                            {formData[element.id] === "" &&
                              formData[element.id].length < 12 &&
                              element.required && (
                                <FormHelperText>
                                  Phone should be 10 digits. Excluding Country
                                  Code.
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}

                        {element.type === "radio" && (
                          <FormControl component="fieldset">
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <RadioGroup
                              row
                              value={formData[element.id] || ""}
                              onChange={(e) => {
                                const selectedOption = e.target.value; // Get the value of the selected radio button

                                setFormData({
                                  ...formData,
                                  [element.id]: selectedOption, // Update the formData with the selected option
                                });
                              }}
                            >
                              {element.options.map((option, j) => (
                                <FormControlLabel
                                  key={j}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.value}
                                />
                              ))}
                            </RadioGroup>

                            {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                        {element.type === "textarea" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            ></label>
                            {/* <InputLabel>
                              {element.value}
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </InputLabel> */}
                            <Input
                              multiline
                              rows={3}
                              value={formData[element.id] || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [element.id]: e.target.value,
                                })
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            />
                            {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}

                        {element.type === "check" && (
                          <FormControl component="fieldset">
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            {element.options.map((option, j) => (
                              <FormControlLabel
                                key={j}
                                control={
                                  <Checkbox
                                    checked={
                                      formData[element.id]?.includes(
                                        option.value
                                      ) || false
                                    }
                                    onChange={(e) => {
                                      const isChecked = e.target.checked; // Check if the checkbox is checked
                                      const selectedOptions = [
                                        ...(formData[element.id] || []),
                                      ]; // Create a copy of selected options
                                      if (isChecked) {
                                        // If checkbox is checked, add option to selected options
                                        selectedOptions.push(option.value);
                                      } else {
                                        // If checkbox is unchecked, remove option from selected options
                                        const index = selectedOptions.indexOf(
                                          option.value
                                        );
                                        if (index !== -1) {
                                          selectedOptions.splice(index, 1);
                                        }
                                      }

                                      setFormData({
                                        ...formData,
                                        [element.id]: selectedOptions, // Update the formData with the selected options
                                      });
                                    }}
                                  />
                                }
                                label={option.value}
                              />
                            ))}
                            {element.options.some((option) =>
                              formData[element.id]?.includes(option.value)
                            ) === false &&
                              element.required && (
                                <FormHelperText>
                                  At least one option must be selected
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}

                        {element.type === "select" && (
                          <FormControl component="fieldset">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                style={{
                                  display: "block", // Ensures the label is on its own line
                                  marginBottom: "8px", // Adds space between label and input
                                  whiteSpace: "pre-line", // Preserves multiline data
                                  fontWeight: "bold", // Optional: Makes the label bold for clarity
                                }}
                              >
                                {element.value}-
                                {element.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </label>
                              <Select
                                style={{
                                  padding: "5px",
                                  fontSize: "16px",
                                  borderColor: "#ccc",
                                  marginRight: "1em",
                                  height: "40px",
                                  lineHeight: "1.5",
                                  marginLeft: "30px",
                                }}
                                value={formData[element.id] || ""}
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  setFormData({
                                    ...formData,
                                    [element.id]: selectedValue,
                                  });
                                }}
                              >
                                {element.options.map((option, j) => (
                                  <MenuItem key={j} value={option.value}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </Select>
                              {element.required && !formData[element.id] && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )}
                            </div>
                          </FormControl>
                        )}

                        {element.type === "multi-select" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block",
                                marginBottom: "8px",
                                whiteSpace: "pre-line",
                                fontWeight: "bold",
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>

                            <Select
                              multiple
                              value={formData[element.id] || []}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [element.id]: e.target.value, // Stores selected values as an array
                                });
                              }}
                              renderValue={(selected) => selected.join(", ")} // Display selected items as comma-separated values
                              // error={
                              //   element.required &&
                              //   (!formData[element.id] ||
                              //     formData[element.id].length === 0)
                              // }
                            >
                              {element.options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  <Checkbox
                                    checked={
                                      formData[element.id]?.includes(
                                        option.value
                                      ) || false
                                    }
                                  />
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>

                            {/* {element.required &&
                              (!formData[element.id] ||
                                formData[element.id].length === 0) && (
                                <FormHelperText style={{ color: "red" }}>
                                  At least one option must be selected
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}

                        {element.type === "date" && (
                          <FormControl fullWidth>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <label
                                style={{
                                  display: "block",
                                  marginBottom: "8px",
                                  whiteSpace: "pre-line",
                                  fontWeight: "bold",
                                }}
                              >
                                {element.value} -{" "}
                                {element.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </label>
                              <TextField
                                type="date"
                                value={formData[element.id] || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    [element.id]: e.target.value, // e.g., "2023-06-15"
                                  })
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  formData[element.id] === "" &&
                                  element.required
                                }
                              />
                            </Box>

                            {formData[element.id] === "" &&
                              element.required && (
                                <FormHelperText style={{ color: "red" }}>
                                  This field is required
                                </FormHelperText>
                              )}
                          </FormControl>
                        )}
                        {element.type === "bdate" && (
                          <FormControl fullWidth>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <label
                                style={{
                                  display: "block",
                                  marginBottom: "8px",
                                  whiteSpace: "pre-line",
                                  fontWeight: "bold",
                                }}
                              >
                                {element.value} -{" "}
                                {element.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </label>
                              <TextField
                                type="date"
                                value={formData[element.id] || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    [element.id]: e.target.value,
                                  })
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                // error={(element.required && !formData[element.id]) && (
                                //   <FormHelperText style={{ color: "red" }}>
                                //     This field is required
                                //   </FormHelperText>
                                // )}
                              />
                            </Box>
                            {/* {formData[element.id] === "" &&
                              element.required && (
                                <FormHelperText style={{ color: "red" }}>
                                  This field is required
                                </FormHelperText>
                              )}
                            {formData[element.id] !== "" &&
                              !isDateValid(formData[element.id]) && (
                                <FormHelperText style={{ color: "red" }}>
                                  You must be at least 18 years old
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}

                        {element.type === "link" && (
                          <FormControl fullWidth>
                            <label
                              style={{
                                display: "block", // Ensures the label is on its own line
                                marginBottom: "8px", // Adds space between label and input
                                whiteSpace: "pre-line", // Preserves multiline data
                                fontWeight: "bold", // Optional: Makes the label bold for clarity
                              }}
                            >
                              {element.value}-
                              {element.required && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <Input
                              value={formData[element.id] || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [element.id]: e.target.value,
                                })
                              }
                              error={
                                formData[element.id] === "" && element.required
                              }
                            />
                            {/* {formData[element.value] === "" &&
                              element.required && (
                                <FormHelperText>
                                  This field is required
                                </FormHelperText>
                              )} */}
                          </FormControl>
                        )}
                      </Grid>
                    ))}

                    {/* <Box sx={{ mb: 2 }}>
                      <div>
                        {activeStep !== 0 && ( // Conditionally render the Back button when activeStep is not 0
                          <Button
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                            variant="outlined"
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          onClick={
                            activeStep === totalSteps - 1
                              ? onSubmit
                              : handleNext
                          }
                          disabled={
                            (activeStep === totalSteps - 1 &&
                              Object.keys(formData).length <
                                data[activeStep].elements.length) ||
                            !isFormValid() // Disable if required fields are empty
                          }
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {activeStep === totalSteps - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </Box> */}

                    {/* Bug Fiex for single section finish=> formvalidation not working finish button not coming needs to fill all the fields*/}

                    {/* {console.log(moment(contestData?.contestEndDate).isAfter(currentDate),"yestyest",yest.isAfter(currentDate),"---",todayAfter.isAfter(currentDate))} */}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {activeStep !== 0 && ( // Conditionally render the Back button when activeStep is not 0
                          <Button
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                            variant="outlined"
                          >
                            Back
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          // color="secondary"
                          disabled={checkDateIsAfterCurrentDate(
                            contestData?.contestEndDate
                          )}
                          onClick={handleDraft}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Draft
                        </Button>
                        <Button
                          variant="contained"
                          onClick={
                            activeStep === totalSteps - 1
                              ? onSubmit
                              : handleNext
                          }
                          disabled={
                            !isFormValid() ||
                            (activeStep !== 0 &&
                              Object.keys(formData).length <
                                data[activeStep].elements.length) ||
                            checkDateIsAfterCurrentDate(
                              contestData?.contestEndDate
                            )
                          }
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {activeStep === totalSteps - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            {activeStep === totalSteps && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button
                  disabled={checkDateIsAfterCurrentDate(
                    contestData?.contestEndDate
                  )}
                  onClick={handleReset}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default VerticalLinearStepper;
