import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person"; // Importing PersonIcon from Material-UI Icons
import CustomizedProgressBars from "../CustomizedProgressBars";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Utility/Helper";
import { styled } from "@mui/material";

const settings = ["My Application", "My Profile", "Change Password", "Logout"];

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // margin: 2,
  width: "100%",  // Ensures the container takes the full width of the window
  height: "auto",  // Height will adjust based on the image's aspect ratio
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const BannerImage = styled("img")(({ theme }) => ({
  width: "100%",  // Ensures the image takes up the full width of the container
  height: "auto",  // Maintain aspect ratio as the width changes
}));

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userData, setUserData] = React.useState(); // Initialize as null or an empty object
  const { contestId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    if (token) {
      fetchUserData(token.userId);
    }
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    navigate(`/login/${contestId}`);
    toast.success("Logged Out successfully!", {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  };

  const handleChangepassword = () => {
    navigate(`/change-password/${contestId}`);
  };
  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/${userId}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }finally {
      setLoading(false);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (setting) => {
    handleCloseUserMenu();
    if (setting === "Logout") {
      handleLogout();
    } else if (setting === "Change Password") {
      handleChangepassword();
    } else if (setting === "My Application") {
      // Example: Redirect to /dashboard/${contestId}
      navigate(`/dashboard/${contestId}`);
    } else if (setting === "My Profile") {
      navigate(`/profile/${contestId}`);
    }
  };

    const [data, setData] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
  
    const validationURL = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${contestId}`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        console.log(response.ok,"response")
        if (response.ok) {
        
          const data1 = await response.json();
          // Log the response body
  
          setData(data1);
  
  
  
                  // Fetch the image URL from Firebase Storage
                  if (
                    data1.contestBanner &&
                    data1.contestBanner[0] &&
                    data1.contestBanner[0].uploadedFileName
                  ) {
                    // Fetch the image URL from Firebase Storage
                    const fileRef = ref(
                      storage,
                      `/contest-banner/${data1.contestBanner[0].uploadedFileName}`
                    );
                    try {
                      const downloadURL = await getDownloadURL(fileRef);
                      setImageUrl(downloadURL);
                    } catch (error) {
                      console.error("Error fetching image URL:", error);
                     
                    }
                  } else {
                   console.error("Contest banner file name not found in response data");
                   
                  }
  
  
  
  
        } else {
    
          localStorage.clear();
          navigate(`/login/${contestId}`);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Please Check The URL, It's Not a Valid Url", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      } finally {
        setLoading(false);
      }
    };
  
    React.useEffect(() => {
      validationURL();
    }, [contestId]);
  
    
  if (loading) {
    return (
      <>
        <CustomizedProgressBars />
      </>
    );
  }
  return (
    <>
    <AppBar
      position="static"
      sx={{
        background: "#43C6AC", // fallback for old browsers
        background: "-webkit-linear-gradient(to right, #191654, #43C6AC)", // Chrome 10-25, Safari 5.1-6
        background: "linear-gradient(to right, #191654, #43C6AC)", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
          
          <img src="/iimcip-logo.png" alt="logo" width="50" />
         
         

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
            {userData ? (
              <Typography variant="body1" sx={{ mr: 2, color: "inherit" }}>
                Welcome, {userData.firstName} {userData.lastName} (Reg Id-{userData.registrationId})
              </Typography>
            ) : null}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Subha"
                  src="/static/images/avatar/2.jpg"
                  sx={{
                    backgroundColor: "#0066ff", // Blue background color
                  }}
                >
                  <PersonIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuClick(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

    <Box sx={{ flexGrow: 1 }}>
            {
              imageUrl &&
        <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
          <Toolbar style={{padding:0}}>
          
            <ImageContainer >
              <BannerImage 
           
               src={imageUrl} alt="Banner" />
            </ImageContainer>
          </Toolbar>
        </AppBar>
            }
      </Box>
    
    </>
  );
}

export default Navbar;
