import React, { useEffect, useState } from "react";
import { AppBar, Box, Grid, styled, Toolbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import VerticalLinearStepper from "../../../VerticalLinearStepper";
import { toast } from "react-toastify";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../../Utility/Helper";

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // margin: 2,
  width: "100%",  // Ensures the container takes the full width of the window
  height: "auto",  // Height will adjust based on the image's aspect ratio
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const BannerImage = styled("img")(({ theme }) => ({
  width: "100%",  // Ensures the image takes up the full width of the container
  height: "auto",  // Maintain aspect ratio as the width changes
}));

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


const Home = () => {
  const navigate = useNavigate();
  const { contestId } = useParams();
  const [loading, setLoading] = useState(true);
  const [urlValid, setUrlValid] = useState(false);
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${contestId}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
        }
      );
      console.log(response.ok,"response")
      if (response.ok) {
        setUrlValid(true);
        const data1 = await response.json();
        // Log the response body

        setData(data1);



                // Fetch the image URL from Firebase Storage
                if (
                  data1.contestBanner &&
                  data1.contestBanner[0] &&
                  data1.contestBanner[0].uploadedFileName
                ) {
                  // Fetch the image URL from Firebase Storage
                  const fileRef = ref(
                    storage,
                    `/contest-banner/${data1.contestBanner[0].uploadedFileName}`
                  );
                  try {
                    const downloadURL = await getDownloadURL(fileRef);
                    setImageUrl(downloadURL);
                  } catch (error) {
                    console.error("Error fetching image URL:", error);
                   
                  }
                } else {
                 console.error("Contest banner file name not found in response data");
                 
                }




      } else {
        setUrlValid(false);
        localStorage.clear();
        navigate(`/login/${contestId}`);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It's Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [contestId, navigate]);

  

  if (loading) {
    return <CustomizedProgressBars />;
  }

  return (
    <>
      {urlValid ? (
        <>
          <Navbar
           
          />



          <Grid container spacing={2} alignItems="center">
        <Grid item mt={1} xs={11} container justifyContent="flex-end">
          {/* Removed buttons here */}
        </Grid>
        <Grid item xs={12}>
          <VerticalLinearStepper id={contestId} contestData={data} />
        </Grid>
      </Grid>
        </>
      ) : (
        <PageNotFound />
      )}
      {/* <Footer/> */}
    </>
  );
};

export default Home;
