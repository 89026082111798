import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Navbar from "../Navbar";
import { Container, Box } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import Footer from "../Footer";
import FormPreview from "../FormPreview";
import { languageType } from "../../../Utility/Helper";

const ProfilePage = () => {
  const { id } = useParams();
  const location = useLocation(); // Get the current location object

  // console.log("ID FROM PROFILE PAGE---",location)
  const navigate = useNavigate();
  const { contestId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [urlValid, setUrlValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = React.useState(null); // Initialize as null or an empty object
  const path = location.pathname;
  // const isSpecificURL = path.includes("01aea58e-470f-457a-aee8-97bc8037823f");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    // sector: "",
    // stage: "",
    location: "", // Ensure this field is included
    // businessStructure: "",
    email: "",
    mobileNumber: "", // Ensure this field is included
    password: "",
    confirmPassword: "",
    hearAboutUs: "",
    language: "",
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    companyName: false,
    email: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
    // sector: false,
    // stage: false,
    location: false,
    // businessStructure: false,
    hearAboutUs: false, // Add hearAboutUs to errors
    language: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false); // Add this line
  const [sectorData, setSectorData] = useState([]);
  const [stageData, setStageData] = useState([]); // Add state for stage data
  const [locationData, setLocationData] = useState([]); // Add state for location data
  const [businessStructureData, setBusinessStructureData] = useState([]); // Add state for business structure data
  const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/${user.userId}`,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        // Set initial form data based on fetched user data
        setFormData({
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          companyName: data.companyName || "",
          // sector: data.sector || "",
          // stage: data.stage || "",
          location: data.state || "", // Ensure this is correctly mapped
          // businessStructure: data.businessStructure || "",
          email: data.email || "",
          mobileNumber: data.phoneNumber || "", // Ensure this is correctly mapped
          hearAboutUs: data.hearAboutUs || "",
          language: data.language || "",
        });
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${contestId}`,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );
      if (response.ok) {
        setUrlValid(true);
        // Fetch the sector dropdown list
        const sectorResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/sector/drop-down-list`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (sectorResponse.ok) {
          const sectorData = await sectorResponse.json();
          setSectorData(sectorData); // Assuming you have a state to hold sector data
        } else {
          console.error("Failed to fetch sector data");
        }

        // Fetch the Stage dropdown list

        const stageResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/stage/drop-down-list`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (stageResponse.ok) {
          const stageData = await stageResponse.json();
          setStageData(stageData);
        } else {
          console.error("Failed to fetch stage data");
        }

        // Fetch Location

        const locationResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/state/drop-down-list`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (locationResponse.ok) {
          const locationData = await locationResponse.json();
          setLocationData(locationData);
        } else {
          console.error("Failed to fetch location data");
        }

        // Fetch Business Structure

        const businessStructureResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/business-structure/drop-down-list`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (businessStructureResponse.ok) {
          const businessStructureData = await businessStructureResponse.json();
          setBusinessStructureData(businessStructureData);
        } else {
          console.error("Failed to fetch business structure data");
        }

        // Hear About us api call
        const hearResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/hear-about/drop-down-list`,
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        if (hearResponse.ok) {
          const hearData = await hearResponse.json();
          setHearAboutUsOptions(hearData);
        } else {
          console.error("Failed to fetch stage data");
        }

        fetchUserData();
      } else {
        setUrlValid(false);
        localStorage.clear();
        navigate(`/login/${contestId}`);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It`s Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [contestId, navigate]);

  if (loading) {
    return <CustomizedProgressBars />;

  }
  // useEffect(() => {
  //   // Validate form on first submit button click
  //   if (isFirstSubmit) {
  //     const formErrors = {};
  //     let hasErrors = false;

  //     Object.entries(formData).forEach(([key, value]) => {
  //       if (value.trim() === "") {
  //         formErrors[key] = true;
  //         hasErrors = true;
  //       }
  //     });

  //     // Check email format
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     if (formData.email.trim() !== "" && !emailRegex.test(formData.email)) {
  //       formErrors.email = true;
  //       hasErrors = true;
  //     }

  //     // Check mobile number digits
  //     if (
  //       formData.mobileNumber.trim() !== "" &&
  //       (formData.mobileNumber.trim().length !== 10 ||
  //         isNaN(formData.mobileNumber.trim()))
  //     ) {
  //       formErrors.mobileNumber = true;
  //       hasErrors = true;
  //     }

  //     // Check first name length
  //     if (formData.firstName.trim().length < 2) {
  //       formErrors.firstName = true;
  //       hasErrors = true;
  //     }

  //     // Check company name length
  //     if (formData.companyName.trim().length < 2) {
  //       formErrors.companyName = true;
  //       hasErrors = true;
  //     }

  //     // Check last name length
  //     if (formData.lastName.trim().length < 2) {
  //       formErrors.lastName = true;
  //       hasErrors = true;
  //     }

  //     setErrors(formErrors);

  //     // Set isFirstSubmit to false after initial form validation
  //     setIsFirstSubmit(false);
  //   }
  // }, [formData, isFirstSubmit]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "",
    }));
  };

  const handleSectorChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      sector: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      sector: value === "",
    }));
  };
  const handleStageChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      stage: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      stage: value === "",
    }));
  };
  const handleLocationChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      location: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      location: value === "",
    }));
  };

  const handleBusinessStructureChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      businessStructure: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      businessStructure: value === "",
    }));
  };

  const handleChange = (e) => {
  const { name, value } = e.target;
  const trimmedValue = value.trim();

  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));

  // Validation logic
  let error = false;

  if (name === "mobileNumber") {
    error = trimmedValue.length !== 10 || isNaN(trimmedValue);
  } else if (["firstName", "lastName", "companyName"].includes(name)) {
    error = trimmedValue.length < 2;
  } else {
    error = trimmedValue === "";
  }

  setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: error,
  }));

  // Reset password match error when the password fields change
  if (name === "password" || name === "confirmPassword") {
    setPasswordMatchError(false);
  }
};

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));

  //   // Validation logic on blur
  //   if (name === "mobileNumber") {
  //     if (value.trim().length !== 10 || isNaN(value.trim())) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: true,
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: false,
  //       }));
  //     }
  //   } else if (name === "firstName" || name === "lastName" ) {
  //     if (value.trim().length < 2) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: true,
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: false,
  //       }));
  //     }
  //   } else {
  //     if (value.trim() === "") {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: true,
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: false,
  //       }));
  //     }
  //   }

  //   // Reset password match error on password change
  //   if (name === "password" || name === "confirmPassword") {
  //     setPasswordMatchError(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFirstSubmit(true);

    // Check for sector, stage, location, businessStructure, and hearAboutUs selection
    // if (
    //   formData.sector === "" ||
    //   formData.stage === "" ||
    //   formData.location === "" ||
    //   formData.businessStructure === "" ||
    //   formData.hearAboutUs === "" ||
    //   formData.language === ""
    // ) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     sector: formData.sector === "",
    //     stage: formData.stage === "",
    //     location: formData.location === "",
    //     businessStructure: formData.businessStructure === "",
    //     hearAboutUs: formData.hearAboutUs === "",
    //     language: formData.language === "",
    //   }));
    //   return;
    // }
    // if (isSpecificURL) {
    //   if (
    //     formData.location === "" ||
    //     formData.hearAboutUs === "" ||
    //     formData.language === ""
    //   ) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       location: formData.location === "",
    //       hearAboutUs: formData.hearAboutUs === "",
    //       language: formData.language === "",
    //       sector: false,
    //       stage: false,
    //       businessStructure: false,
    //     }));
    //     return;
    //   }
    // } else {
   
    // }



     
    if (
      // formData.sector === "" ||
      // formData.stage === "" ||
      formData.location === "" ||
      // formData.businessStructure === "" ||
      formData.hearAboutUs === "" ||
      formData.language === ""
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        // sector: formData.sector === "",
        // stage: formData.stage === "",
        location: formData.location === "",
        // businessStructure: formData.businessStructure === "",
        hearAboutUs: formData.hearAboutUs === "",
        language: formData.language === "",
      }));
      return;
    }
    const hasErrors =
      Object.values(errors).some((error) => error === true) ||
      passwordMatchError;

    if (hasErrors) {
      // If there are errors, do not proceed with submission
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      // If passwords do not match, show an error message
      toast.error("Both Passwords must match!", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
      return;
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/application/${user.userId}`, // Include the formId in the URL for PATCH
          {
            method: "PATCH", // Change to PATCH
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${user.accessToken}`, // Pass access token in the headers
            },
            body: JSON.stringify({
              firstName: formData.firstName,
              lastName: formData.lastName,
              companyName: formData.companyName,

              phoneNumber: formData.mobileNumber,

              // sector: formData.sector,
              // stage: formData.stage,
              state: formData.location,
              // businessStructure: formData.businessStructure,
              hearAboutUs: formData.hearAboutUs,
              language: formData.language,
            }),
          }
        );

        if (response.ok) {
          // Navigate to the home page
          navigate(`/dashboard/${contestId}`);
          // Perform a hard refresh
          toast.success("Updated Profile Data Successfully.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          });
        } else {
          const errorMessage = await response.json(); // Assuming error message is in JSON format
          console.error("Update failed:", errorMessage.message);

          if (
            Array.isArray(errorMessage.message) &&
            errorMessage.message.length > 0
          ) {
            errorMessage.message.forEach((error) => {
              toast.error(error, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                hideProgressBar: true,
              });
            });
          } else if (typeof errorMessage.message === "string") {
            console.error("Error Message:", errorMessage.message);
            toast.error(errorMessage.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            });
          } else {
            // In case the error message is not an array or string, display a generic error
            toast.error(
              "An unexpected error occurred. Please try again later.",
              {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                hideProgressBar: true,
              }
            );
          }
        }
      } catch (error) {
        console.error("Update failed:", error.message);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  return (
    <>
      {urlValid ? (
        <>
          <Navbar />
          {/* Strt */}
          <Container style={{ marginBottom: "45px" }}>
            <Grid
              container
              spacing={5}
              alignItems="center"
              justifyContent="center"
              py={1}
              my={4}
            >
              {/* For Demo Purpose */}
              {/* <Grid item xs={12} md={5}>
                <Box textAlign="center">
                  <img
                    src="https://bootstrapious.com/i/snippets/sn-registeration/illustration.svg"
                    alt=""
                    className="img-fluid mb-3 d-none d-md-block"
                    style={{
                      maxWidth: "400px",
                      background: "cadetblue",
                      borderRadius: "50px",
                      border: "15px solid cadetblue",
                    }}
                  />
                </Box>
              </Grid> */}

              {/* Registration Form */}
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h5"
                  color="primary"
                  textAlign={"center"}
                  sx={{ mb: 2 }}
                  gutterBottom
                >
                  My Profile{" "}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} direction="column" mb={3}>
                    {/* First Name */}
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <PermIdentityIcon
                                sx={{
                                  mr: 1,
                                  color: errors.firstName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.firstName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.firstName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.firstName
                              ? "First Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>{" "}
                      {/* Last Name */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <PermIdentityIcon
                                sx={{
                                  mr: 1,
                                  color: errors.lastName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.lastName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.lastName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.lastName
                              ? "Last Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />{" "}
                      </Grid>
                    </Grid>

                    {/* Company Name */}

                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Company Name"
                          name="companyName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <BusinessIcon
                                sx={{
                                  mr: 1,
                                  color: errors.companyName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.companyName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.companyName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.companyName
                              ? "Company Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>{" "}
                    </Grid>
                    {/* Sector and Stage */}

                    {/* <Grid item container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="sector-select-label">
                                Sector
                              </InputLabel>
                              <Select
                                labelId="sector-select-label"
                                id="sector-select"
                                value={formData.sector}
                                onChange={handleSectorChange}
                                label="Sector"
                                error={!!errors.sector}
                              >
                                {sectorData.map((sector) => (
                                  <MenuItem
                                    key={sector._id}
                                    value={sector.name}
                                  >
                                    {sector.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.sector && (
                                <FormHelperText error>
                                  Please Select Sector
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="stage-select-label">
                                Stage
                              </InputLabel>
                              <Select
                                labelId="stage-select-label"
                                id="stage-select"
                                value={formData.stage}
                                onChange={handleStageChange}
                                label="Stage"
                                error={!!errors.stage}
                              >
                                {stageData.map((stage) => (
                                  <MenuItem key={stage._id} value={stage.name}>
                                    {stage.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.stage && (
                                <FormHelperText error>
                                  Please Select Stage
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>{" "}
                        </Grid> */}
                    {/* {!isSpecificURL && (
                      <>
                        
                      </>
                    )} */}

                    {/* Location States ////   Business */}

                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="location-select-label">
                            Location/State
                          </InputLabel>
                          <Select
                            labelId="location-select-label"
                            id="location-select"
                            value={formData.location}
                            onChange={handleLocationChange}
                            label="Location/State"
                            error={!!errors.location}
                          >
                            {locationData.map((location) => (
                              <MenuItem
                                key={location._id}
                                value={location.name}
                              >
                                {location.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.location && (
                            <FormHelperText error>
                              Please Select Location/State
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* Buysiness Dropdown */}

                      {/* <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="business-structure-select-label">
                                Business Structure
                              </InputLabel>
                              <Select
                                labelId="business-structure-select-label"
                                id="business-structure-select"
                                value={formData.businessStructure}
                                onChange={handleBusinessStructureChange}
                                label="Business Structure"
                                error={!!errors.businessStructure}
                              >
                                {businessStructureData.map((structure) => (
                                  <MenuItem
                                    key={structure._id}
                                    value={structure.name}
                                  >
                                    {structure.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.businessStructure && (
                                <FormHelperText error>
                                  Please Select Business Structure
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid> */}
                    {/* </Grid> */}

                    {/* Email Address */}
                    {/* <Grid item container spacing={2}> */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Email"
                          name="email"
                          size="small"
                          value={formData.email}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.email}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <EmailIcon
                                sx={{
                                  mr: 1,
                                  color: errors.email ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          type="email"
                          helperText={errors.email ? "Invalid Email" : ""}
                          autoComplete="off"
                          disabled // Add disabled prop to disable the input field
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Mobile Number"
                          name="mobileNumber"
                          size="small"
                          value={formData.mobileNumber}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.mobileNumber}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                              <LocalPhoneIcon sx={{
                                mr: 1,
                                color: errors.mobileNumber
                                  ? "red"
                                  : "inherit",
                                maxWidth: "18px",
                              }}
                          />
                              +91
                            </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          helperText={
                            errors.mobileNumber
                              ? "Mobile number needs 10 digits"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>
                    {/* </Grid> */}

                    {/* Hear About Us */}
                    {/* <Grid item container spacing={2}> */}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="hear-about-us-select-label">
                            From Where Do You Hear About Us?
                          </InputLabel>
                          <Select
                            labelId="hear-about-us-select-label"
                            id="hear-about-us-select"
                            name="hearAboutUs"
                            value={formData.hearAboutUs}
                            onChange={handleDropdownChange}
                            label="From Where Do You Hear About Us?"
                            error={!!errors.hearAboutUs}
                          >
                            {hearAboutUsOptions.map((option) => (
                              <MenuItem key={option._id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.hearAboutUs && (
                            <FormHelperText error>
                              Please Select Where You Heard About Us
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {/* Language */}

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="language-label">
                            Language to be used in Application
                          </InputLabel>
                          <Select
                            labelId="language-label"
                            id="language-select"
                            name="language"
                            value={formData.language}
                            onChange={handleDropdownChange}
                            label="Language to be used in Application"
                            error={!!errors.language}
                          >
                            {languageType.map((option) => (
                              <MenuItem
                                key={`lang-${option.value}`}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.language && (
                            <FormHelperText error>
                              Please Select Language to be used in Application
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Grid item container spacing={2}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, marginRight: 2 }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>{" "} */}
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Update{" "}
                        </Button>
                      </Grid>
                    </Grid>

                    {/* Divider Text */}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
      {/* <Footer/> */}
    </>
  );
};

export default ProfilePage;
